
import CardBox from '@/component/cardBox'
import DialogBox from '@/component/dialog'
import CitySelect from '@/component/selectCity'
import BlankSelector from 'yinzhilv-js-sdk/frontend/common-project/component/haolv/blank-selector/1.0.0/index.vue';
import updateBankAccount from '@/lib/data-service/haolv-default/consumer_web_bankAccount_updateBankAccount'
import getBankAccountList from '@/lib/data-service/haolv-default/consumer_web_bankAccount_getBankAccountList'
import delBankAccount from '@/lib/data-service/haolv-default/consumer_web_bankAccount_delBankAccount'
import saveBankAccount from '@/lib/data-service/haolv-default/consumer_web_bankAccount_saveBankAccount'
import bankSelect from '../components/bankSelect.js';
import { verifySpace } from '@/lib/utils/verify'

export default {
    name: "corporateAccount",
    data() {
        var validatorCityName = (rule, value, callback) => {
            if (!this.formData.cityId) {
                callback(new Error("请选择城市"));
            } else {
                callback();
            }
        }
        return {
            cardText: [
                {
                    title: "企业名称：",
                    parameter: "bankUserName"
                },
                // {
                //     title: "开户行：",
                //     parameter: "openingBank"
                // },
                {
                    title: "账号：",
                    parameter: "bankAccount"
                },
                {
                    title: "开户支行：",
                    parameter: "openingBankBranch"
                },
            ],
            cardTextAlipay: [
                {
                    title: "企业名称：",
                    parameter: "bankUserName"
                },
                {
                    title: "账号：",
                    parameter: "bankAccount"
                },
            ],
            initCity: {},
            bankShow: false,
            bankList: [],
            alipayShow: false,
            alipayList: [],
            dialogType: '',
            formData: {
            },
            alipayForm: {
            },
            searchCriteria: '',
            rules: {
                bankUserName: [
                    { required: true, message: '请输入用户名', trigger: 'blur' },
                    { validator: verifySpace }
                ],
                bankAccount: [
                    { required: true, message: '请输入银行卡号', trigger: 'blur' },
                    { validator: verifySpace }
                ],
                openingBank: [
                    { required: true, message: '请输入开户行', trigger: 'blur' },
                ],
                cityId: [
                    { required: true, message: '请选择城市', trigger: ['change', 'blur'] },
                    { validator: validatorCityName }
                ],
                openingBankBranch: [
                    { required: true, message: '请输入开户网点', trigger: 'blur' },
                    { validator: verifySpace }
                ],
            },
        };
    },

    created() {
        this.getCorporateAccountList()
    },
    mounted() {

    },
    components: { CardBox, DialogBox, CitySelect, BlankSelector },
    mixins: [bankSelect],
    methods: {
        editCard(val, type) {
            this[type] = true
            this.formData = Object.assign({}, val)
            this.initCity = Object.assign({}, val)
            this.alipayForm = Object.assign({}, val)
            this.dialogType = 'edit'
        },
        addCard(showName) {
            this.dialogType = 'add'
            this.formData = { accountType: 1 }
            this.alipayForm = { accountType: 2 }
            this.$refs.formData.resetFields();
            this.$refs.alipayForm.resetFields()
            this[showName] = true
        },
        cityInfo(val) {
            let data = {
                provinceName: val.province.name,
                provinceId: val.province.id,
                cityName: val.city.name,
                cityId: val.city.id
            }
            this.formData = Object.assign(this.formData, data)
        },
        alipayCard(val) {
            this.alipayForm = {}
            this.alipayShow = val
        },
        delCorporateAccountList(data) {
            delBankAccount(data).then(res => {
                this.getCorporateAccountList()
                this.$message({ type: "success", message: "删除成功！" })
            })
        },
        addCorporateAccountList(type) {
            let data = this.formData
            type === 'alipay' ? data = this.alipayForm : this.arrangeBankSelect(data)
            saveBankAccount(data).then(res => {
                this.show(type)
                this.getCorporateAccountList()
                this.$message({ type: "success", message: "添加成功！" })
            })
        },
        getCorporateAccountList() {
            let data = {
                searchCriteria: this.searchCriteria || ''
            }
            getBankAccountList(data).then(res => {
                this.bankList = res.datas.filter(val => val.accountType == 1)
                this.alipayList = res.datas.filter(val => val.accountType == 2)
            })
        },
        selectDefault(val) {
            updateBankAccount(val).then(res => {
                this.getCorporateAccountList()
            })
        },
        editCorporateAccountList(type) {
            let data = this.formData
            type !== 'bank' ? data = this.alipayForm : this.arrangeBankSelect(data)
            updateBankAccount(data).then(res => {
                console.log(res)
                this.show(type)
                this.getCorporateAccountList()
                this.$message({ type: "success", message: "修改成功！" })
            })
        },
        show(type) {
            type === 'bank' ? this.bankShow = false : this.alipayShow = false
        },
        setCorporateAccountList(self, type) {
            let _this = this
            let name = type === 'bank' ? 'formData' : 'alipayForm'
            name === 'bank' ? this.getBankSelect('openingBank') : ''
            _this.$refs[name].validate(valid => {
                if (valid) {
                    try {
                        if (_this.dialogType === 'add') {
                            this.addCorporateAccountList(type)
                        } else {
                            this.editCorporateAccountList(type)
                        }
                    } catch { }
                } else {
                    return false
                }
            })
        },
        cancel(val) {
            this.formData = {}
            this.bankShow = val
        },
        deleteCard(val) {
            this.$confirm('确定要删除操作吗？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.delCorporateAccountList({ id: val.id })
            }).catch(() => {

            });
        },
    }
};